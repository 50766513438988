import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';
import { DASHBOARD_PATH } from 'config';

// Dashboard routing
const DashboardSummary = Loadable(lazy(() => import('../views/dashboard/Summary')));
const DashboardTechnicalPeformance = Loadable(lazy(() => import('../views/dashboard/TechnicalPerformance')));

// Financial Page
const FinPerfStakingReport = Loadable(lazy(() => import('../views/dashboard/FinancialPerformance/pages/StakingReport')));
const FinPerfRevenueYieldAnalysis = Loadable(lazy(() => import('../views/dashboard/FinancialPerformance/pages/RevenueYieldAnalysis')));
const FinPerfETHrBalances = Loadable(lazy(() => import('../views/dashboard/FinancialPerformance/pages/ETHrBalances')));

// Glossary / Legal page routing
const GlossaryFaq = Loadable(lazy(() => import('../views/pages/GlossaryFAQ')));
const LegalPage = Loadable(lazy(() => import('../views/pages/Legal')));
const PrivacyPage = Loadable(lazy(() => import('../views/pages/Privacy')));

// Utilities routing
const TermsAndConditions = Loadable(lazy(() => import('../views/pages/Terms')));
const UserWelcome = Loadable(lazy(() => import('../views/pages/welcome/UserWelcome')));

// Settings routing
const SettingsManageValidators = Loadable(lazy(() => import('../views/settings/manage-validators')));
const SettingsAccountProfile = Loadable(lazy(() => import('../views/settings/account-profile')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <Navigate to={DASHBOARD_PATH} />
        },
        {
            path: DASHBOARD_PATH,
            element: <DashboardSummary />
        },

        {
            path: '/dashboard/financialPerformance/stakingReport',
            element: <FinPerfStakingReport />
        },
        {
            path: '/dashboard/financialPerformance/revenueYieldAnalysis',
            element: <FinPerfRevenueYieldAnalysis />
        },
        {
            path: '/dashboard/financialPerformance/ethrBalances',
            element: <FinPerfETHrBalances />
        },
        {
            path: '/dashboard/technicalPerformance',
            element: <DashboardTechnicalPeformance />
        },
        {
            path: '/settings/manage-validators',
            element: <SettingsManageValidators />
        },
        {
            path: '/settings/account-profile',
            element: <SettingsAccountProfile />
        },
        {
            path: '/glossary-faq',
            element: <GlossaryFaq />
        },
        {
            path: '/legal',
            element: <LegalPage />
        },
        {
            path: '/privacy',
            element: <PrivacyPage />
        },

        {
            path: '/terms-conditions',
            element: <TermsAndConditions />
        },
        {
            path: '/welcome',
            element: <UserWelcome />
        }
    ]
};

export default MainRoutes;
