import Error from 'views/pages/maintenance/Error';

// ==============================|| ROUTING RENDER ||============================== //
const MaintenanceRoutes = {
    path: '/',
    children: [
        {
            path: '404',
            element: <Error />
        },
        {
            path: '*',
            element: <Error />
        }
    ]
};

export default MaintenanceRoutes;
