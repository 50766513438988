import { isRejectedWithValue } from '@reduxjs/toolkit';
import { openSnackbar } from '../slices/snackbar';

const systemErrorDisplayMiddleware = (state) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const { status, data } = action.payload;
        console.log('Something went wrong: ', JSON.stringify(action.payload));
        const snackBarPayload = {
            open: true,
            message: `Something went wrong.`,
            variant: 'alert',
            alert: {
                color: 'error'
            },
            transition: 'SlideUp',
            close: true,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
        };
        const secondaryMessage = [];
        if (status) {
            secondaryMessage.push(`Status: ${status}`);
        }
        if (data) {
            if (data.detail && !/html/gi.test(data.detail)) {
                secondaryMessage.push(data.detail);
            } else if (!/html/gi.test(JSON.stringify(data))) {
                secondaryMessage.push(JSON.stringify(data));
            }
        }
        if (secondaryMessage.length > 0) {
            snackBarPayload.secondaryMessage = secondaryMessage.join(' / ');
        }
        state.dispatch(openSnackbar(snackBarPayload));
    }

    return next(action);
};

export default systemErrorDisplayMiddleware;
