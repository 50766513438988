import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import axios from 'axios';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { REGISTER, LOGOUT, ACCOUNT_INITIALIZE } from 'store/actions';
import { useSelector, useDispatch } from 'store';

// project imports
import Loader from 'ui-component/Loader';
import configData from '../config';

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Authorization ${token}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const state = useSelector((state) => state.account);
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                const { token } = state;
                if (token && verifyToken(token)) {
                    setSession(token);
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = async (userEmail, userPassword) => {
        const apiUrl = `${configData.API_SERVER}users/login`;
        const response = await axios.post(apiUrl, {
            password: userPassword,
            email: userEmail
        });
        setSession(response.data.token);
        dispatch({
            type: ACCOUNT_INITIALIZE,
            payload: {
                isLoggedIn: true,
                user: response.data.user,
                token: response.data.token,
                termsAccepted: response.data.termsAccepted,
                termsAcceptedDate: response.data.termsAcceptedDate
            }
        });
    };

    const register = async (email, password) => {
        const apiUrl = `${configData.API_SERVER}users/register`;
        const response = await axios.post(apiUrl, {
            username: email,
            password,
            email
        });
        dispatch({
            type: REGISTER,
            payload: {
                user: { email }
            }
        });
        return response;
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {
        // do stuff
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
