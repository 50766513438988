// import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// import Page403 from './StatusCodePages/Page403';
// import Page404 from './StatusCodePages/Page404';

// -----------------------|| COMMON - ERROR HANDLER CARD ||-----------------------//

// eslint-disable-next-line react/prop-types
const ErrorPage = ({ error }) => {
    // const location = useLocation();
    // eslint-disable-next-line react/prop-types
    const messageToDisplay = error ? error.message : null;
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 168px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                    <Typography color={theme.palette.error.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                        Sorry, something went wrong.
                                    </Typography>
                                </Stack>
                                {messageToDisplay && (
                                    <Stack sx={{ p: 1 }}>
                                        <Box sx={{ p: 1, border: '1px dashed grey' }}>
                                            <pre>{messageToDisplay}</pre>
                                        </Box>
                                    </Stack>
                                )}
                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                    <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                        Please logout, refresh, and login again.
                                        {/* <Button onClick={resetErrorBoundary}>Try again</Button> */}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
