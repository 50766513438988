// assets
import {
    IconBrandFramer,
    IconSettings,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLayoutGridAdd,
    IconUserCheck
} from '@tabler/icons';

// constant
const icons = {
    IconSettings,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconBrandFramer,
    IconLayoutGridAdd,
    IconUserCheck
};

// -----------------------|| UTILITIES MENU ITEMS ||-----------------------//

const settings = {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    children: [
        {
            id: 'settings-manage-validators',
            title: 'Manage Validators',
            type: 'item',
            url: '/settings/manage-validators',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'settings-account-profile',
            title: 'Account Profile',
            type: 'item',
            url: '/settings/account-profile',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
    ]
};
export default settings;
