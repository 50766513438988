import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, MaintenanceRoutes]);
}
