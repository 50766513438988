// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - user validator data reducer
export const GET_USER_VALIDATOR_DATA = '@uservalidator/GET_USER_VALIDATOR_DATA';
export const USER_VALIDATOR_ERROR = '@uservalidator/USER_VALIDATOR_ERROR';

// action - user validator apr data reducer
export const SET_USER_VALIDATOR_APR_DATA = '@uservalidatoraprdata/SET_USER_VALIDATOR_APR_DATA';
export const USER_VALIDATOR_APR_DATA_ERROR = '@uservalidatoraprdata/USER_VALIDATOR_APR_DATA_ERROR';

// action - validator rewards data reducer
export const SET_VALIDATOR_REWARDS = '@validatorewards/SET_VALIDATOR_REWARDS';
export const GET_VALIDATOR_REWARDS = '@validatorewards/GET_VALIDATOR_REWARDS';
export const VALIDATOR_REWARDS_ERROR = '@validatorewards/VALIDATOR_REWARDS_ERROR';

// action - user profile data reducer
export const GET_USER_PROFILE_DATA = '@userprofile/GET_USER_PROFILE_DATA';
export const USER_PROFILE_DATA_ERROR = '@userprofile/USER_PROFILE_DATA_ERROR';
export const SET_BASE_CURRENCY = '@userprofile/SET_BASE_CURRENCY';
