// assets
import { IconBuildingBank, IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBuildingBank,
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// -----------------------|| OTHER MENU ITEMS ||-----------------------//

const documentation = {
    id: 'documentation',
    type: 'group',
    children: [
        {
            id: 'glossary-faq',
            title: 'Glossary / FAQ',
            type: 'item',
            url: '/glossary-faq',
            icon: icons.IconHelp,
            breadcrumbs: false
        },
        {
            id: 'legal',
            title: 'Legal',
            type: 'item',
            url: '/legal',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        }
    ]
};
export default documentation;
