// third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import accountReducer from './accountReducer';
import snackbarReducer from './slices/snackbar';
import systemErrorDisplayMiddleware from './middleware/system-error-display';
import userReducer from './slices/user';
import menuReducer from '../menu-items/slice';
import { ethcapApi } from 'features/api/apiSlice';
// eslint-disable-next-line camelcase
import { beaconcha_inApi } from 'features/api/beaconcha_inApiSlice';
import config from 'config';

// ==============================|| REDUX - MAIN STORE ||============================== //

const middleware = [
    ethcapApi.middleware,
    // eslint-disable-next-line camelcase
    beaconcha_inApi.middleware
];

// Only show system errors in local and development
if (/dev|local/i.test(config.CURRENT_ENVIRONMENT)) {
    middleware.push(systemErrorDisplayMiddleware);
}
const store = configureStore({
    reducer: {
        account: persistReducer(
            {
                key: 'account',
                storage,
                keyPrefix: 'ethcap-'
            },
            accountReducer
        ),
        snackbar: snackbarReducer,
        user: userReducer,
        menu: menuReducer,
        [ethcapApi.reducerPath]: ethcapApi.reducer,
        // eslint-disable-next-line camelcase
        [beaconcha_inApi.reducerPath]: beaconcha_inApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(middleware)
});

const persister = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, persister, dispatch, useSelector, useDispatch };
