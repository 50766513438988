import LAYOUT_CONST from 'constant';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
    authDomain: 'berry-material-react.firebaseapp.com',
    projectId: 'berry-material-react',
    storageBucket: 'berry-material-react.appspot.com',
    messagingSenderId: '901111229354',
    appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
    measurementId: 'G-MGJHSL8XW3'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';
export const DASHBOARD_PATH = '/dashboard/summary';
export const STAKING_REPORT_PATH = '/dashboard/financialPerformance/stakingReport';
export const HORIZONTAL_MAX_ITEM = 6;

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
    basename: '',
    defaultPath: '/dashboard/summary',
    newUserPath: '/welcome',
    AGGRID_LICENSE_KEY: `CompanyName=Ether Capital,LicensedApplication=Ether Capital Dashboard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-032376,SupportServicesEnd=30_August_2023_[v2]_MTY5MzM1MDAwMDAwMA==dcd5480c53cd7944b9ab779d86f63a0c`, // Renew BEFORE Aug 30 2023
    API_SERVER: process.env.REACT_APP_API_SERVER,
    BEACON_NODE_API_SERVER: process.env.REACT_APP_BEACON_NODE_API_SERVER,
    CURRENT_ENVIRONMENT: process.env.REACT_APP_CURRENT_ENVIRONMENT,
    BASEURL: process.env.REACT_APP_BASE_URL,
    SENTRY_DSN_URL: 'https://92414ea511da45ffae07c96260a2e73a@o1409169.ingest.sentry.io/6745198',
    BEACONCHA_IN_BASE_URL: 'https://beaconcha.in/api/v1/',
    BEACONCHA_IN_API_KEY: process.env.REACT_APP_BEACONCHA_IN_API_KEY
};

export default config;
