// assets
import { IconDashboard, IconReportMoney, IconLayoutDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconLayoutDashboard,
    IconReportMoney
};

// -----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

const Dashboards = {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    children: [
        {
            id: 'summary',
            title: 'Summary',
            type: 'item',
            url: '/dashboard/summary',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false
        },
        {
            id: 'stakingReport',
            title: 'Staking Report',
            type: 'item',
            url: '/dashboard/financialPerformance/stakingReport',
            icon: icons.IconReportMoney,
            breadcrumbs: false
        },
        // {
        //     id: 'financialPerformance',
        //     title: 'Financial Performance',
        //     type: 'collapse',
        //     icon: icons.IconReportMoney,
        //     children: [
        //         {
        //             id: 'stakingReport',
        //             title: 'Staking Report',
        //             type: 'item',
        //             url: '/dashboard/financialPerformance/stakingReport',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'revenueAnalysis',
        //             title: 'Revenue Analysis',
        //             type: 'item',
        //             url: '/dashboard/financialPerformance/revenueYieldAnalysis',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'ethrBalances',
        //             title: 'ETHr Balances',
        //             type: 'item',
        //             url: '/dashboard/financialPerformance/ethrBalances',
        //             breadcrumbs: false
        //         }
        //     ],
        //     breadcrumbs: false
        // },
        {
            id: 'technicalPeformance',
            title: 'Technical Performance',
            type: 'item',
            url: '/dashboard/technicalPerformance',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};
export default Dashboards;
