import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config';

const { BEACONCHA_IN_BASE_URL, BEACONCHA_IN_API_KEY } = config;

// eslint-disable-next-line camelcase
export const beaconcha_inApi = createApi({
    reducerPath: 'beaconcha_inApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BEACONCHA_IN_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set('apikey', BEACONCHA_IN_API_KEY);
        }
    }),
    endpoints: (builder) => ({
        getETHStore: builder.query({
            query: (day = 'latest') => `ethstore/${day}`
        })
    })
});

// eslint-disable-next-line camelcase
export const { useGetETHStoreQuery } = beaconcha_inApi;
