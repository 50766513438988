import { createRoot } from 'react-dom/client';

// third party
import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';
import { BrowserTracing } from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { ConfigProvider } from 'contexts/ConfigContext';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LicenseManager } from 'ag-grid-enterprise/';

// project imports
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorker from 'serviceWorker';
import config, { BASE_PATH } from 'config';
import { store, persister } from 'store';

// style + assets
import 'assets/scss/style.scss';

LicenseManager.setLicenseKey(config.AGGRID_LICENSE_KEY);

// Sentry setup.  Send for all envs except local
if (config.CURRENT_ENVIRONMENT !== 'local') {
    Sentry.init({
        release: 'dashboard-ui-next@0.1.0', // TODO setup automatic release versioning
        dsn: config.SENTRY_DSN_URL,
        environment: config.CURRENT_ENVIRONMENT,
        integrations: [
            new BrowserTracing(),
            new Sentry.Replay(),
            new CaptureConsoleIntegration({
                levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // This sets the sample rate to be 100%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0
    });
}

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
