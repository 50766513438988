// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, REGISTER, SET_TERMS_ACCEPTED } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    termsAccepted: false,
    termsAcceptedDate: null,
    user: null
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token, termsAccepted, termsAcceptedDate } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                termsAccepted,
                termsAcceptedDate,
                token,
                user
            };
        }
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...initialState,
                isInitialized: true
            };
        }
        case SET_TERMS_ACCEPTED: {
            const { termsAcceptedDate } = action.payload;
            return {
                ...state,
                termsAccepted: true,
                termsAcceptedDate
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
