import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config';

const apiUrl = config.API_SERVER;

export const ethcapApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().account.token;
            if (token) {
                headers.set('authorization', `${token}`);
            }
            return headers;
        }
    }),
    tagTypes: ['UserProfile', 'UserValidatorKeys'],
    endpoints: (builder) => ({
        getUserValidatorApr: builder.query({
            query: () => `incomehistory/apr`
        }),
        getUserValidatorBalance: builder.query({
            query: () => `incomehistory/balance`
        }),
        getUserValidatorPerformance: builder.query({
            query: () => `performance/aggregate`
        }),
        getUserValidatorRewards: builder.query({
            query: ({ group, provider } = {}) => {
                const params = {};
                if (group) params.group = group;
                if (provider) params.provider = provider;
                return { url: `incomehistory/series`, params };
            }
        }),
        getUserValidators: builder.query({
            query: () => `uservalidator/grid/`
        }),
        getStockData: builder.query({
            query: () => `stockdata/1/`
        }),
        getMarketData: builder.query({
            query: () => `ethvscurrency/1/`
        }),
        getUserIncomeHistory: builder.query({
            query: (daysInQuery) => `incomehistory/?limit=${daysInQuery}&offset=0`
        }),
        getUserProfile: builder.query({
            query: (id) => `userprofile/${id}`,
            providesTags: ['UserProfile']
        }),
        editUserProfile: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `userprofile/${id}/`,
                method: 'PATCH',
                body: patch
            }),
            invalidatesTags: ['UserProfile']
        }),
        setTermsAccepted: builder.mutation({
            query: (dateAccepted) => ({
                url: 'users/setterms',
                method: 'POST',
                body: { terms_accepted_date: dateAccepted }
            })
        }),

        getRevenueYieldAnalysis: builder.query({
            query: (daysInQuery) => `reports/?limit=${daysInQuery}&offset=0`
        }),
        getConsensusRewards: builder.query({
            query: (daysInQuery) => `reports/ethrconsensusreward/?limit=${daysInQuery}&offset=0`
        }),
        getExecutionRewards: builder.query({
            query: (daysInQuery) => `reports/ethrexecutionreward/?limit=${daysInQuery}&offset=0`
        }),
        getTechnicalPerformanceChartData: builder.query({
            query: ({ group, provider } = {}) => {
                const params = {};
                if (group) params.group = group;
                if (provider) params.provider = provider;
                return { url: `technicalperformance/series`, params };
            }
        }),

        getUserValidatorKeys: builder.query({
            query: () => `uservalidator/grid/`,
            // API returns validator with null values
            transformResponse: (response) => response.filter((key) => key.validatorkey__id !== null),
            providesTags: ['UserValidatorKeys']
        }),
        postStagingValidators: builder.mutation({
            query: (validatorKeys) => ({
                url: `uservalidatorstaging/`,
                method: 'POST',
                body: validatorKeys
            }),
            invalidatesTags: ['UserValidatorKeys']
        }),
        editUserValidatorKeys: builder.mutation({
            query: ({ id, validatorkey }) => ({
                url: `uservalidator/${id}/`,
                method: 'PATCH',
                body: { validatorkey }
            }),
            invalidatesTags: ['UserValidatorKeys']
        }),
        clearUserValidatorKeys: builder.mutation({
            query: () => ({
                url: `validatorgroup/clear`,
                method: 'GET'
            }),
            invalidatesTags: ['UserValidatorKeys']
        }),
        getUserValidatorGroup: builder.query({
            query: ({ limit, offset } = {}) => ({ url: `validatorgroup/`, params: { limit, offset } })
        }),
        editUserValidatorGroup: builder.mutation({
            query: ({ id, group, provider }) => ({
                url: `validatorgroup/${id}/`,
                method: 'PATCH',
                body: { group, provider }
            }),
            invalidatesTags: ['UserValidatorKeys']
        }),
        getUserValidatorLabels: builder.query({
            query: () => `uservalidator/labels/`,
            providesTags: ['UserValidatorLabels']
        }),
        setUserValidatorLabels: builder.mutation({
            query: (newLabels) => ({
                url: `uservalidator/labels/`,
                method: 'POST',
                body: newLabels
            }),
            invalidatesTags: ['UserValidatorLabels']
        }),
        getAttestations: builder.query({
            query: (epochCount = 28) => ({ method: 'GET', url: 'attestations', params: { epoch_count: epochCount } })
        })
    })
});

export const {
    useGetUserValidatorAprQuery,
    useGetUserValidatorBalanceQuery,
    useGetUserValidatorPerformanceQuery,
    useGetUserValidatorRewardsQuery,
    useGetUserValidatorsQuery,
    useGetStockDataQuery,
    useGetMarketDataQuery,
    useGetUserIncomeHistoryQuery,
    useGetUserProfileQuery,
    useEditUserProfileMutation,
    useSetTermsAcceptedMutation,
    useGetRevenueYieldAnalysisQuery,
    useGetConsensusRewardsQuery,
    useGetExecutionRewardsQuery,
    useGetTechnicalPerformanceChartDataQuery,
    useGetUserValidatorKeysQuery,
    usePostStagingValidatorsMutation,
    useEditUserValidatorKeysMutation,
    useClearUserValidatorKeysMutation,
    useGetUserValidatorGroupQuery,
    useEditUserValidatorGroupMutation,
    useGetUserValidatorLabelsQuery,
    useSetUserValidatorLabelsMutation,
    useGetAttestationsQuery
} = ethcapApi;
