import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import { STAKING_REPORT_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const account = useSelector((state) => state.account);
    const { termsAccepted } = account;
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn && termsAccepted) {
            navigate(STAKING_REPORT_PATH, { replace: true });
        } else if (isLoggedIn && !termsAccepted) {
            navigate('/terms-conditions');
        }
    }, [isLoggedIn, termsAccepted, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
