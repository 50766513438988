import dashboard from './dashboards';
import documentation from './documentation';
import settings from './settings';

// -----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, settings]
};

export default menuItems;
